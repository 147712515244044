import * as React from "react"

import { Arrow } from "@components/UI"
import { Carousel } from "@components/Carousel"
import { ClientList } from "@components/ClientList"
import { CursorMarker } from "@components/CursorMarker"
import { Home as Hero } from "@components/Hero"
import { Layout } from "@components/Layout"
import { Lottie } from "@components/Lottie"
import { Seo } from "@components/Seo"
import airgasLottie from "@lottie/airgas_a.json"
import cheesecakeLottie from "@lottie/cheesecake_a.json"
import chessLottie from "@lottie/chess_a.json"
import ecommerceLottie from "@lottie/ecommerce_a.json"
import { splitText } from "@utils"
import { usePageSetup } from "@components/usePageSetup"

const IndexPage = ({ data }) => {
  const { themeColor } = usePageSetup({ themeColor: "bg-teal" })

  return (
    <Layout>
      <Seo
        title="Home"
        description="JK helps you find new, creative ways to connect with the people who matter to your business - with our branding & messaging, employee experience, strategic marketing, and digital services."
      />
      <section className={themeColor}>
        <Hero />
      </section>
      <Arrow colorTop="bg-white" colorBottom="bg-white" fillColor="text-white" />
      <section className="relative z-20 overflow-hidden">
        <div className="container absolute inset-x-0 top-0">
          <div className="aspect-w-1436 aspect-h-165 extend before:bg-teal after:bg-teal"></div>
        </div>
        <div className="container relative flex">
          <div className="w-full sm:w-10/12">
            <Carousel>
              <CursorMarker>
                <CursorMarker.Default />
              </CursorMarker>
              <Carousel.Swiper>
                <Carousel.Slide>
                  <div data-aos="fade-up">
                    <Carousel.Work client="bpm" reverse />
                  </div>
                </Carousel.Slide>
                <Carousel.Slide>
                  <div data-aos="fade-up" data-aos-delay="200">
                    <Carousel.Work client="wb" />
                  </div>
                </Carousel.Slide>
                <Carousel.Slide>
                  <div data-aos="fade-up" data-aos-delay="400">
                    <Carousel.Work client="lumendi" />
                  </div>
                </Carousel.Slide>
                <Carousel.Slide>
                  <div data-aos="fade-up" data-aos-delay="600">
                    <Carousel.Work client="ricoh" />
                  </div>
                </Carousel.Slide>
                <Carousel.Slide>
                  <div data-aos="fade-up" data-aos-delay="800">
                    <Carousel.Work client="weichert" />
                  </div>
                </Carousel.Slide>
              </Carousel.Swiper>
            </Carousel>
          </div>
        </div>
      </section>

      <Arrow colorTop="bg-transparent" colorBottom="bg-gray-darkest" fillColor="text-gray-darkest" />

      <section className="py-clamp-35-80">
        <div className="container flex justify-center">
          <div className="lg:w-10/12">
            <div style={{ maxWidth: "700px" }}>
              <h2
                data-aos="stagger"
                className="text-title"
                dangerouslySetInnerHTML={{
                  __html: splitText({
                    "text-gray-darkest": "Partners who’ve",
                    break: "",
                    "text-teal": "put their trust in JK.",
                  }),
                }}
              />
            </div>
            <ClientList
              clients={[
                "adp",
                "airgas",
                "amazon",
                "caterpillar",
                "csl",
                "dupont",
                "j-and-j",
                "kenvue",
                "netflix",
                "merck",
                "pepsico",
                "philips",
                "priceline",
                "prudential",
                "santander",
                "stryker",
                "children",
                "tiffany",
                "wb",
              ]}
            />
          </div>
        </div>
      </section>

      <Arrow colorTop="bg-gray-darkest" colorBottom="bg-white" fillColor="text-white" />

      <section className="relative overflow-hidden bg-teal-light">
        <div className="container absolute inset-x-0 top-0">
          <div className="aspect-w-1436 aspect-h-165 before:bg-white-dark after:bg-white-dark extend"></div>
        </div>
        <div className="container relative flex">
          <div className="w-full sm:w-10/12">
            <Carousel>
              <CursorMarker>
                <CursorMarker.Default className="bg-white after:text-purple" />
              </CursorMarker>
              <Carousel.Swiper>
                <Carousel.Swiper>
                  <Carousel.Swiper>
                    <Carousel.Slide>
                      <div data-aos="fade-up" data-aos-delay={200 * 0}>
                        <Carousel.Quote client="adp"></Carousel.Quote>
                      </div>
                    </Carousel.Slide>
                    <Carousel.Slide>
                      <div data-aos="fade-up" data-aos-delay={200 * 1}>
                        <Carousel.Quote client="ans"></Carousel.Quote>
                      </div>
                    </Carousel.Slide>
                    <Carousel.Slide>
                      <div data-aos="fade-up" data-aos-delay={200 * 2}>
                        <Carousel.Quote client="ricoh"></Carousel.Quote>
                      </div>
                    </Carousel.Slide>
                    <Carousel.Slide>
                      <div data-aos="fade-up" data-aos-delay={200 * 3}>
                        <Carousel.Quote client="ad"></Carousel.Quote>
                      </div>
                    </Carousel.Slide>
                  </Carousel.Swiper>
                </Carousel.Swiper>
              </Carousel.Swiper>
            </Carousel>
          </div>
        </div>
      </section>

      <Arrow colorTop="bg-transparent" colorBottom="bg-gray-darkest" fillColor="text-gray-darkest" />

      <section className="bg-teal-light pb-clamp-18-80 pt-clamp-35-80">
        <div className="container flex justify-center">
          <div className="w-full lg:w-10/12">
            <div style={{ maxWidth: "400px" }}>
              <h2
                data-aos="stagger"
                className="text-title"
                dangerouslySetInnerHTML={{
                  __html: splitText({
                    "text-gray-darkest": "What we're",
                    "text-white": "working on.",
                  }),
                }}
              />
            </div>
            <div className="grid mt-24 sm:grid-cols-2 gap-y-16 gapy-x-4">
              <div className="sm:pr-12">
                <div data-aos="fade-up" id="recent-work-1" className="flex items-center h-38">
                  <Lottie width="170px" height="170px" animation={cheesecakeLottie} />
                </div>
                <p data-aos="fade-up" data-aos-anchor="#recent-work-1" className="mt-clamp-5-10">
                  We're creating new ways for one of America's most-loved casual dining restaurants{" "}
                  <span className="font-bold">to better connect with their people,</span> from the kitchen to the front
                  of house.
                </p>
              </div>
              <div className="sm:pl-12">
                <div data-aos="fade-up" data-aos-delay="100" id="recent-work-2" className="flex items-center h-38">
                  <Lottie width="165px" height="151px" animation={ecommerceLottie} />
                </div>
                <p data-aos="fade-up" data-aos-delay="100" data-aos-anchor="#recent-work-2" className="mt-clamp-5-10">
                  We’re working closely with a leading B2B e-commerce platform to{" "}
                  <span className="font-bold">
                    reevaluate their brand positioning, reimagine their brand architecture, and tell a new,
                    results-based story
                  </span>{" "}
                  that showcases the business-building value they create for their clients.
                </p>
              </div>
              <div className="sm:pr-12">
                <div data-aos="fade-up" id="recent-work-3" className="flex items-center h-38">
                  <Lottie width="150px" height="156px" animation={chessLottie} />
                </div>
                <p data-aos="fade-up" data-aos-anchor="#recent-work-3" className="mt-clamp-5-10">
                  We’re helping one of the country’s largest public accounting and advisory firms{" "}
                  <span className="font-bold">capture hearts and minds with future-focused value stories</span> for
                  their clients and their people.
                </p>
              </div>
              <div className="sm:pl-12">
                <div data-aos="fade-up" data-aos-delay="100" id="recent-work-4" className="flex items-center h-38">
                  <Lottie width="150px" height="150px" animation={airgasLottie} />
                </div>
                <p data-aos="fade-up" data-aos-delay="100" data-aos-anchor="#recent-work-4" className="mt-clamp-5-10">
                  We’re helping a leading U.S. distributor of industrial, medical, and specialty gasses to{" "}
                  <span className="font-bold">drive recruitment and retention</span> through an authentic and engaging
                  new talent story.
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>
      <Arrow colorTop="bg-gray-darkest" colorBottom="bg-gray-darkest" fillColor="text-gray-darkest" />
    </Layout>
  )
}

export default IndexPage
